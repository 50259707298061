import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

export default new (class NoticeHomeAPI {
  public async getHomeNoticeFeed(): Promise<any> {
    const action = Action.newsFeed;
    const response = await getApi(action);
    return response as any;
  }
})();
