























































// export default {
//     name: 'newsMarquee'
// }
import { Vue, Component} from "vue-property-decorator";
import NoticeHome from "@/store/modules/noticeHome";

@Component
export default class NewsMarquee extends Vue {
    get news() {
        return NoticeHome.newsList;
    }
    newsDetail(id: string) {
        this.$router.push({
        name: "PublicNoticeDetail",
        params: {
            id: id,
        },
        });
    }
}
