import {
  Module,
  getModule,
  VuexModule,
  MutationAction,
} from "vuex-module-decorators";
import store from "@/store";
import { NewsFeed } from "../models/newsFeed";
import NoticeHomeAPI from "@/store/api/noticeHome";

@Module({
  namespaced: true,
  name: "noticeHome",
  store,
  dynamic: true,
})
class NoticeHome extends VuexModule {
  public newsList: NewsFeed[] = [];

  @MutationAction
  async getNotice() {
    const response = await NoticeHomeAPI.getHomeNoticeFeed();
    return { newsList: response.results };
  }
}

export default getModule(NoticeHome);
