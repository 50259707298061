



































































import { Vue, Component } from "vue-property-decorator";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

@Component({
  components: {
    Splide,
    SplideSlide,
  },
})
export default class Slider extends Vue {
  data() {
    return {
      options: {
        type: "fade",
        rewind: true,
        autoplay: true,
        arrows: false,
        perPage: 1,
        interval: 3000,
        easing: "ease",
      },
    };
  }
}
