







































































































import { Vue, Component } from "vue-property-decorator";
import { MetaModule as metaStore } from "@/store/modules";
import { SourceDestination } from "@/store/models/vechileSchedule";

@Component
export default class HomePgTravelForm extends Vue {
  departure: string = "";
  departureOptions: SourceDestination[] = [];
  destination: string = "";
  destinationOptions: SourceDestination[] = [];
  selectedShift: string = "";
  shiftOptions = [
    { value: "Day", text: "Day" },
    { value: "Night", text: "Night" },
    { value: "Both", text: "Both" },
  ];

  async created() {
    await metaStore.loadSourceDestinationDD();
    this.departureOptions = metaStore.sourceDestinationList;
  }

  async onDepartureSelect() {
    await metaStore.loadDestinationOnBasisOfSource(this.departure);
    this.destinationOptions = metaStore.destinationOnBasisOfSourceList;
  }

  async onSearchClick() {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.$router.push({
        name: "PlanTravel",
        query: {
          source: this.departure,
          destination: this.destination,
          shift: this.selectedShift,
        },
      });
    }
  }
}
