
















































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import NewsMarquee from "@/components/NewUIComponents/newsMarquee.vue";
import TravelForm from "@/components/NewUIComponents/homePgTravelForm.vue";
import Slider from "@/components/NewUIComponents/slider.vue";

// Store
import NoticeHome from "@/store/modules/noticeHome";
import {
  MetaModule as metaStore,
  JWTModule,
  MessagingModule,
  CommonStore,
} from "@/store/modules";
import { RootRouter } from "@/utils/newRoutePathConstant";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    NewsMarquee,
    Slider,
    TravelForm,
  },
})
export default class newHome extends Vue {
  trackVehicle = {
    vehicleNum: "",
    id: "",
  };

  isNewMsg: boolean = false;
  displayChathead: boolean = true;
  newalertmessage: string = "new message";

  get news() {
    return NoticeHome.newsList.slice(0, 3);
  }

  async created() {
    await metaStore.loadVehicleListDD();
    await NoticeHome.getNotice();
    this.isNewMsg = MessagingModule.newChatCount;
    eventHub.$on("show-chat-head", this.show);
  }

  show() {
    this.displayChathead = CommonStore.isChatHeadActive;
  }

  get isChatHeadActive() {
    return MessagingModule.isMessageBoxActive;
  }

  get userDetail() {
    return JWTModule.UserDetail;
  }

  get options1() {
    return metaStore.vehicleList;
  }

  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  get RootRouter() {
    return RootRouter;
  }

  get NewMsg() {
    let new_msg = CommonStore.isNewMsg;
    if (new_msg === true) {
      this.newalertmessage = "new message";
    } else {
      this.newalertmessage = "";
    }
    return CommonStore.isNewMsg;
  }

  get hasNewMsg() {
    return CommonStore.isNewMsg;
  }

  async onMessageButtonClicked(e: any) {
    CommonStore.setNewMsg(false);
    MessagingModule.setIsMessageBoxActive(true);
  }

  async onTrackClick() {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      const vehicleToTrack = this.options1.find(
        (x) => x.vehicleNumber === this.trackVehicle.vehicleNum
      );
      if (vehicleToTrack && vehicleToTrack.id) {
        this.trackVehicle.id = vehicleToTrack.id.toString();

        this.$router.push({
          name: "TrackVehicle",
          query: { vn: this.trackVehicle.id },
        });
      }
    }
  }
}
