var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plan-travel-wrap d-flex align-items-center"},[_c('b-container',[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('div',{staticClass:"plan-travel-box"},[_c('h3',{staticClass:"text-center"},[_vm._v("Plan your travel")]),_c('b-form',[_c('b-form-group',{staticClass:"mb-2",class:{
                'is-error': _vm.fieldHasErrors('Departure'),
                'is-required': _vm.isFieldRequired('Departure'),
              },attrs:{"id":"departure-input","label-for":"departure-input","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Departure "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-select",attrs:{"id":"departure-input","options":_vm.departureOptions,"value-field":"id","text-field":"name","name":"Departure"},on:{"change":_vm.onDepartureSelect},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- Please select Departure --")])]},proxy:true}]),model:{value:(_vm.departure),callback:function ($$v) {_vm.departure=$$v},expression:"departure"}}),(_vm.fieldHasErrors('Departure'))?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.errors.first("Departure"))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-2",class:{
                'is-error': _vm.fieldHasErrors('Destination'),
                'is-required': _vm.isFieldRequired('Destination'),
              },attrs:{"id":"destination-input","label-for":"destination-input","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Destination "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-select",attrs:{"id":"destination-input","options":_vm.destinationOptions,"value-field":"id","text-field":"name","name":"Destination"},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}}),(_vm.fieldHasErrors('Destination'))?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.errors.first("Destination"))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Select Shift","label-class":"form-label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-shifts","options":_vm.shiftOptions,"aria-describedby":ariaDescribedby,"name":"radios-btn","plain":""},model:{value:(_vm.selectedShift),callback:function ($$v) {_vm.selectedShift=$$v},expression:"selectedShift"}})]}}])}),_c('div',{staticClass:"d-grid gap-2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.onSearchClick}},[_vm._v(" Search "),_c('i',{staticClass:"icon-arrow-right2"})])])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }